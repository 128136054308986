import React, { useState, useEffect } from 'react'
import { Track } from '@platform/database'
import { GithubUserEvent } from '@platform/github'

// import your route components
import './Sandbox.css'

const {
  REACT_APP_SPOTIFY_CLIENT_ID,
  REACT_APP_SPOTIFY_REDIRECT_URI,
  REACT_APP_GITHUB_CLIENT_ID,
  REACT_APP_GITHUB_REDIRECT_URI
}: any = process.env

if (!REACT_APP_SPOTIFY_CLIENT_ID)
  throw new Error('REACT_APP_SPOTIFY_CLIENT_ID not set')
if (!REACT_APP_SPOTIFY_REDIRECT_URI)
  throw new Error('REACT_APP_SPOTIFY_REDIRECT_URI not set')
if (!REACT_APP_GITHUB_CLIENT_ID)
  throw new Error('REACT_APP_GITHUB_CLIENT_ID not set')
if (!REACT_APP_GITHUB_REDIRECT_URI)
  throw new Error('REACT_APP_GITHUB_REDIRECT_URI not set')

// move to helper/util
const generateRandomString = (length: number) => {
  var text = ''
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

// move to api file
// receive the token as an argument
const spotifyUrl = (() => {
  const state = generateRandomString(16)
  const stateKey = 'spotify_auth_state'

  localStorage.setItem(stateKey, state)
  const scope =
    'user-read-private user-read-email user-read-playback-state user-modify-playback-state playlist-read-private playlist-read-collaborative playlist-modify-private'

  let url = 'https://accounts.spotify.com/authorize'
  url += '?response_type=token'
  url += '&client_id=' + encodeURIComponent(REACT_APP_SPOTIFY_CLIENT_ID)
  url += '&scope=' + encodeURIComponent(scope)
  url += '&redirect_uri=' + encodeURIComponent(REACT_APP_SPOTIFY_REDIRECT_URI)
  url += '&state=' + encodeURIComponent(state)
  return url
})()

const githubUrl = `https://github.com/login/oauth/authorize?
client_id=${REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${REACT_APP_GITHUB_REDIRECT_URI}&scope=repo`
console.log(githubUrl)
console.log(spotifyUrl)
const { REACT_APP_API_URL }: any = process.env
const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

const getPlaylists = (sessionToken: string) =>
  fetch(apiUrl('/list-playlists'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => x.playlists)

const getCurrentTrack = (sessionToken: string): Promise<Track> =>
  fetch(apiUrl('/get-current-track'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then(({ track }) => track)

const getCurrentDj = (sessionToken: string) =>
  fetch(apiUrl('/get-current-dj'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then(({ user }) => user)

const getTrackHistory = (sessionToken: string) =>
  fetch(apiUrl('/user-track-history'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then(({ trackPlays, message }) => {
      if (!trackPlays) throw new Error(message)
      return trackPlays
    })

const pause = async (sessionToken: string) =>
  fetch(apiUrl('/pause'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => {
      console.log(x)
      return x
    })

const play = async (sessionToken: string) =>
  fetch(apiUrl('/play'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => {
      console.log(x)
      return x
    })

const getUserHealth = async (sessionToken: string) =>
  fetch(apiUrl('/user-health'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => {
      console.log(x)
      return x
    })

const addCurrentSongToPlaylist = async (
  sessionToken: string,
  playlistName: string
) =>
  fetch(apiUrl('/add-current-track-to-playlist'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ playlistName })
  })
    .then((x) => x.json())
    .then((x) => {
      console.log(x)
      return x
    })

const search = async (
  sessionToken: string,
  { q, type }: { q: string; type?: string }
) => {
  return fetch(apiUrl('/search'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      q,
      type
    })
  }).then((x) => x.json())
}

const getGithubUserEvents = (sessionToken: string) =>
  fetch(apiUrl('/github-user-events'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => x.events)

// need to handle loading and error states
function Sandbox() {
  const [sessionToken, setSessionToken] = useState('')

  // Move to search component
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState({
    albums: { items: [] },
    artists: { items: [] },
    tracks: { items: [] }
  } as {
    albums: { items: any[] }
    artists: { items: any[] }
    tracks: { items: any[] }
  })

  // Move to DJ component
  const [currentDj, setCurrentDj] = useState({ name: '', playing: false })
  const [currentTrackLabel, setCurrentTrackLabel] = useState('')
  const [djHeartRate, setDjHeartRate] = useState<number>(52)

  // Move to player component
  const [playlistName, setPlaylistName] = useState('')
  const [recentlyModifiedPlaylist, setRecentlyModifiedPlaylist] = useState({
    name: ''
  })

  // Library comonent
  const [playlists, setPlaylists] = useState([] as any[])
  const [trackHistory, setTrackHistory] = useState([] as any[])
  const [githubUserEvents, setGithubUserEvents] = useState(
    [] as GithubUserEvent[]
  )

  useEffect(() => {
    const sessionJson = localStorage.getItem('session')
    if (sessionJson) {
      const sessionToken = JSON.parse(sessionJson).token
      if (sessionToken) {
        setSessionToken(sessionToken)
        getCurrentTrack(sessionToken)
          .then((track) => {
            if (!track) return
            const artistsLabel = track.artists
              .map(({ name }: { name: string }) => name)
              .join(', ')
            setCurrentTrackLabel(`${track.name} - ${artistsLabel}`)
          })
          .catch(console.error)
        getPlaylists(sessionToken).then(setPlaylists).catch(console.error)
        getCurrentDj(sessionToken).then(setCurrentDj).catch(console.error)
        // getTrackHistory(sessionToken).then(setTrackHistory).catch(console.error)
        // Disabled because rate limiting..
        // getUserHealth(sessionToken).then(({data: {heartRate}})=> {
        //   setDjHeartRate(heartRate.lastSevenDaysAvgRestingHeartRate)
        // })

        // Requires github authenticated
        // getGithubUserEvents(sessionToken)
        //   .then(setGithubUserEvents)
        //   .catch(console.error)
      }
    }
  }, [])

  const onPlaylistNameInput = (
    e: any /* React.FormEvent<HTMLInputElement> */
  ) => setPlaylistName(e?.target.value)

  const onSearchTextInput = (e: any /* React.FormEvent<HTMLInputElement> */) =>
    setSearchText(e?.target.value)

  const onAddSongToPlaylistButtonClick = async () => {
    addCurrentSongToPlaylist(sessionToken, playlistName).then(
      setRecentlyModifiedPlaylist
    )
    return recentlyModifiedPlaylist
  }

  const onSearchButtonClick = async () => {
    search(sessionToken, { q: searchText }).then(({ result }) =>
      setSearchResult(result)
    )
  }

  const onPlayButtonClick = async () => {
    play(sessionToken)
  }

  const onPauseButtonClick = async () => {
    pause(sessionToken)
  }

  return (
    <div>
      <a href={spotifyUrl}>Login with Spotify</a>
      <a href={githubUrl}>Login with Github</a>

      <div>
        <h3>Recent github activity</h3>
        {githubUserEvents
          ?.filter(function isGithubPushEvent(event: GithubUserEvent): boolean {
            return event.type === 'PushEvent'
          })
          .map((pushEvent) => (
            <div>
              <div>
                {pushEvent.org?.login}: {pushEvent.payload?.commits?.length}{' '}
                commit(s) [
                {new Date(pushEvent.created_at).toLocaleDateString('en-us', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
                ]
              </div>
              {/* <div><p><pre>{JSON.stringify(pushEvent.payload, null, 2)}</pre></p></div> */}
            </div>
          ))}
      </div>

      <div>
        <h3>
          Currently djing: {currentDj.name} (heart rate = {djHeartRate}bpm)
        </h3>
        <h2>{currentTrackLabel}</h2>
        <button onClick={onPlayButtonClick}>Play</button>
        <button onClick={onPauseButtonClick}>Pause</button>
      </div>

      <div>
        <input
          name="playlistName"
          type="text"
          onInput={onPlaylistNameInput}
        ></input>
        <button onClick={onAddSongToPlaylistButtonClick}>
          Add current song to playlist by name
        </button>
        {recentlyModifiedPlaylist.name && (
          <p>
            Added {currentTrackLabel} to playlist{' '}
            {recentlyModifiedPlaylist.name}!
          </p>
        )}
      </div>

      <div>
        <h3>Recent plays</h3>
        {trackHistory
          .filter(({ track }) => track)
          .map(({ track }) => {
            const artistsLabel = track.artists
              .map(({ name }: { name: string }) => name)
              .join(', ')

            return (
              <div>
                {track.name} - {artistsLabel}
              </div>
            )
          })}
      </div>

      <div>
        <h3>Playlists</h3>
        {playlists?.map((playlist) => (
          <div>{playlist.name}</div>
        ))}
      </div>

      <div>
        <h3>Search</h3>
        <input
          name="searchText"
          type="text"
          onInput={onSearchTextInput}
        ></input>
        <button onClick={onSearchButtonClick}>Search for music</button>
      </div>

      {searchResult.albums.items.length ||
      searchResult.tracks.items.length ||
      searchResult.artists.items.length ? (
        <div>
          <h4>Albums</h4>
          <div>
            {searchResult.albums.items.slice(10).map((album) => {
              const artistsLabel = album.artists
                .map(({ name }: { name: string }) => name)
                .join(', ')

              return (
                <div>
                  {album.name} - {artistsLabel}
                </div>
              )
            })}
          </div>

          <h4>Artists</h4>
          <div>
            {searchResult.artists.items.slice(10).map((artist) => (
              <div>{artist.name}</div>
            ))}
          </div>

          <h4>Tracks</h4>
          <div>
            {searchResult.tracks.items.slice(10).map((track) => {
              const artistsLabel = track.artists
                .map(({ name }: { name: string }) => name)
                .join(', ')
              return (
                <div>
                  {track.name} - {artistsLabel}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Sandbox
