import React, { useState, useEffect } from 'react'
import { Entity } from '@platform/database'
import { EntityForm } from './EntityForm'

const { REACT_APP_API_URL }: any = process.env
const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

const getEntities = () =>
  fetch(apiUrl('/list-entities'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => x.entities)

export default function Generator() {
  const [entities, setEntities] = useState<Entity[]>([])
  const [newEntity, setNewEntity] = useState<Entity>({} as any)

  useEffect(() => {
    getEntities().then(setEntities)
  }, [])

  if (entities.length) {
    return (
      <div>
        <div>
          <EntityForm entity={newEntity}></EntityForm>
        </div>
        {entities.map((entity) => (
          <div key={entity.id}>
            {entity.name}
            <EntityForm entity={entity}></EntityForm>
          </div>
        ))}
      </div>
    )
  }

  return <div>Loading...</div>
}
