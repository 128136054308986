import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Session } from '@platform/database'

export default function Spotify() {
  // state
  const [token, setToken] = useState('') // todo: refactor away from <Navigate />
  // #access_token=XXXXX&token_type=Bearer&expires_in=3600&state=XXXXX
  const queryString = window.location.hash.replace('#', '?')
  const { access_token, token_type, expires_in } = parseQuery(queryString)

  // exit if no creds
  if (!access_token) throw new Error('access_token not set')
  if (!token_type) throw new Error('token_type not set')
  if (!expires_in) throw new Error('expires_in not set')

  // save creds locally
  localStorage.setItem('access_token', access_token)
  localStorage.setItem('token_type', token_type)
  localStorage.setItem('expires_in', expires_in)

  // save creds api
  const { REACT_APP_API_URL }: any = process.env
  const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

  // move to api file
  const apiLogin = async (spotifyToken: string, sessionToken?: string) => {
    const headers: any = {
      'Content-Type': 'application/json'
    }
    if (sessionToken) headers.Authorization = `Bearer ${sessionToken}`
    return fetch(apiUrl('/user-login'), {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
      body: JSON.stringify({ spotifyToken })
    })
      .then((res) => res.json())
      .then(({ session }: { session: Session }) => {
        localStorage.setItem('session', JSON.stringify(session))
        setToken(session.token)
      })
  }

  useEffect(() => {
    if (!token) {
      try {
        const sessionToken = JSON.parse(
          localStorage.getItem('session') || ''
        ).token
        apiLogin(access_token, sessionToken)
      } catch (err) {
        apiLogin(access_token)
      }
    }
  }, [])

  if (token) {
    return <Navigate to="/" />
  }
  return <div>Loading...</div>
}

// move to util
function parseQuery(queryString: string) {
  const query: any = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
