import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Track } from '@platform/database'
import { GithubUserEvent } from '@platform/github'

// import your route components
import './App.css'

import Github from './Github'
import Spotify from './Spotify'
import Entity from './Entity'
import Generator from './Generator'
import Sandbox from './Sandbox'

function App() {
  const [sessionToken, setSessionToken] = useState('')

  useEffect(() => {
    const sessionJson = localStorage.getItem('session')
    if (sessionJson) {
      const sessionToken = JSON.parse(sessionJson).token
      if (sessionToken) {
        setSessionToken(sessionToken)
      }
    }
  }, [])

  return (
    <div>
      <BrowserRouter basename="/app/">
        <Routes>
          <Route path="/" element={<Sandbox />} />
        </Routes>
        <Routes>
          <Route path="/spotify" element={<Spotify />} />
        </Routes>
        <Routes>
          <Route path="/github" element={<Github />} />
        </Routes>
        <Routes>
          <Route path="/entity" element={<Entity />} />
        </Routes>
        <Routes>
          <Route path="/generator" element={<Generator />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
