import React, { useState, useEffect } from 'react'
import { Entity } from '@platform/database'
import { EntityForm } from './EntityForm'

const { REACT_APP_API_URL }: any = process.env
const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

const getEntities = () =>
  fetch(apiUrl('/list-entities'), {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((x) => x.json())
    .then((x) => x.entities)

export default function Generator() {
  const [entityId, setEntityId] = useState<Entity['id']>('')
  const [entity, setEntity] = useState<Entity>()
  const [entities, setEntities] = useState<Entity[]>([] as Entity[])

  const onSelectEntity: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    setEntityId(ev.target.value)
    const found = entities.find(({ id }) => id === ev.target.value)
    console.log({found})
    if (found) setEntity(found)
  }
  useEffect(() => {
    getEntities().then(setEntities)
  }, [])

  return (
    <div>
      <select value={entityId} onChange={onSelectEntity}>
        {entities.map((entity) => (
          <option key={entity.id} value={entity.id}>
            {entity.name}
          </option>
        ))}
      </select>
      {entity ? (
        <div>
          <EntityForm entity={entity}></EntityForm>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
