import React, { useState } from 'react'
import fetch from 'node-fetch'
import { Entity } from '@platform/database'

const { REACT_APP_API_URL }: any = process.env
const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

const createEntity = (name: Entity['name']) =>
  fetch(apiUrl('/create-entity'), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({name})
  })
    .then((x) => x.json())
    .then((x) => x.entity)

export const EntityForm = (props: {
  entity: Entity
  onSave?(entity: Entity): any
}) => {
  const [id, setId] = useState(props.entity.id)
  const [name, setName] = useState(props.entity.name)

  // const [properties, setProperties] = useState([])

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // update existing
    if (props.entity.id) {
    }

    // save new
    else {
      await createEntity(name).then(async (entity) => {
        setId(entity.id)
        setName(entity.name)
        if (props.onSave) await props.onSave(entity)
      })
    }
  }

  return (
    <form onSubmit={onSubmitForm}>
      <label htmlFor="nameInput">name</label>
      <input
        name="nameInput"
        value={name}
        type="string"
        placeholder="BlogPost"
        required={true}
        onChange={onChangeName}
      ></input>

      <input type="submit" value="Save"></input>
    </form>
  )
}
