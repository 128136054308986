import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Session } from '@platform/database'

export default function Github() {
  const [token, setToken] = useState('') // todo: refactor away from <Navigate />
  const queryString = window.location.search
  const { code: githubCode } = parseQuery(queryString)

  // exit if no creds
  if (!githubCode) throw new Error('Expected query parameter "code=xxx"')
  // save creds api
  const { REACT_APP_API_URL }: any = process.env
  const apiUrl = (urlPath: string) => `${REACT_APP_API_URL}${urlPath}`

  // move to api file
  const apiLogin = async (githubCode: string, sessionToken?: string) =>
    {
      const headers: any = {
        'Content-Type': 'application/json'
      }
      if (sessionToken) headers.Authorization = `Bearer ${sessionToken}`

      return fetch(apiUrl('/user-login'), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers,
        body: JSON.stringify({ githubCode })
      })
        .then((res) => res.json())
        .then(({ session }: { session: Session} ) => {
          localStorage.setItem('session', JSON.stringify(session))
          setToken(session.token)
        })
    }

    useEffect(() => {
      if (!token) {
        try {
          const sessionToken = JSON.parse(
            localStorage.getItem('session') || ''
          ).token
          apiLogin(githubCode, sessionToken)
        } catch (err) {
          apiLogin(githubCode)
        }
      }
    }, [])

  if (token) {
    return <Navigate to="/" />
  }
  return <div>Loading...</div>
}

// move to util
function parseQuery(queryString: string) {
  const query: any = {}
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
